import * as React from "react"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import Logo from "../components/Logo"

type LinkNode = {
	order: number
	url: string
	id: string
}
interface PressLinkNode extends LinkNode {
	img: string
	source: string
	quote: string
}

interface YTLinkNode extends LinkNode {
	name: string
	formattedImg: {
		relativePath: string
		childImageSharp: {
			gatsbyImageData: IGatsbyImageData
		}
	}
}

const PressPage = () => {
	const data = useStaticQuery(graphql`
		{
			allPressLinks {
				nodes {
					order
					url
					id
					img
					source
					quote
				}
			}
			allYouTubeLinks {
				nodes {
					order
					url
					id
					name
					formattedImg {
						relativePath
						childImageSharp {
							gatsbyImageData(width: 200, layout: FIXED)
						}
					}
				}
			}
		}
	`)
	return (
		<>
			<Helmet>
				<title>Google Feud - Press</title>
			</Helmet>
			<Logo />
			<div id="PressWrapper" style={{ padding: "0 var(--single)" }}>
				<StaticImage
					imgStyle={{ borderRadius: "5px" }}
					style={{ maxWidth: "700px", margin: "var(--single) 0" }}
					objectFit="scale-down"
					src="../images/midnight.jpg"
					loading="eager"
					alt="Google Feud on @midnight"
				/>
				<h2>Press</h2>
				<ul>
					{data.allPressLinks.nodes
						.sort((a: PressLinkNode, b: PressLinkNode) => a.order - b.order)
						.map((val: PressLinkNode) => (
							<li
								key={val.id}
								style={{
									margin: "var(--double)",
									lineHeight: "150%",
								}}>
								<OutboundLink href={val.url || ""} target="_blank">
									<div>
										<div style={{ display: "inline" }}>
											{val.quote && `“${val.quote}” – `}
											{val.source}
										</div>
										{val.img && (
											<img
												style={{
													display: "inline-block",
													position: "relative",
													left: "10px",
													top: "3px",
													width: "20px",
												}}
												src={val.img}
												alt={`${val.source} Icon`}
											/>
										)}
									</div>
								</OutboundLink>
							</li>
						))}
					<li key="webby">
						<OutboundLink
							target="_blank"
							href="https://winners.webbyawards.com/2016/websites-and-mobile-sites/general-websites-and-mobile-sites/games/161775/google-feud">
							<StaticImage
								style={{ maxWidth: "150px" }}
								src="../images/webby.png"
								alt="Webby Award Winner - Best Game - People's Voice"
								objectFit="scale-down"
								loading="lazy"
							/>
						</OutboundLink>
					</li>
				</ul>
				<h2 style={{ marginTop: "var(--triple)" }}>YouTube</h2>
				<ul id="youtube" style={{ display: "inline" }}>
					{data.allYouTubeLinks.nodes
						.sort((a: YTLinkNode, b: YTLinkNode) => a.order - b.order)
						.map(
							(val: YTLinkNode) =>
								val.formattedImg && (
									<li
										key={val.id}
										style={{
											margin: "var(--double)",
											lineHeight: "150%",
											display: "inline-block",
											width: "200px",
										}}>
										{val.formattedImg.childImageSharp.gatsbyImageData && (
											<OutboundLink href={val.url} target="_blank">
												<GatsbyImage
													image={
														val.formattedImg.childImageSharp.gatsbyImageData
													}
													alt={`${val.name} - YouTube`}
												/>
											</OutboundLink>
										)}
										<OutboundLink href={val.url} target="_blank">
											<span
												style={{
													fontSize: "12px",
													fontFamily: "var(--font2)",
												}}>
												{val.name}
											</span>
										</OutboundLink>
									</li>
								)
						)}
				</ul>
			</div>
		</>
	)
}
export default PressPage
